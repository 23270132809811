import React from 'react';
import {navigate} from 'gatsby';
import auth from 'utils/auth';

const Callback = () => {
  auth.handleAuthentication((profile, err) => {
    if (err) {
      navigate('/');
      return;
    }

    navigate('/app');
  });
  return <div>Logging you in...</div>;
};

export default Callback;
